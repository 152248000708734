<template>
  <v-app
    id="app"
  >
    <v-dialog
      v-model="notLoggedIn"
      persistent
      width="350"
    >
      <v-card>
        <v-card-title class="justify-center">
          <span
            class="text-h3"
            style="word-break: break-word;"
          >
            {{ fvLoadingMessage }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            indeterminate
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <field-vue-modal
      :visibility="displayBrowserDialog"
      :title="dialogBrowserTitle"
      :description="dialogBrowserDescription"
      :modal-style="'warning'"
      :hide-cancel="true"
      :hide-okay="true"
    />
    <div v-show="isChrome">
      <v-dialog
        :value="swDownloading"
        persistent
        max-width="300"
        overlay-opacity=".75"
      >
        <v-card class="text-left">
          <v-card-title class="v-dialogHeader text-left px-3 py-1">
            Update
          </v-card-title>
          <v-card-text
            class="px-3 py-1"
          >
            {{ updateText }}
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="!updateExists"
              color="accent"
              to="/today"
              @click="refreshApp"
            >
              Okay
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="displayAppDialog"
        persistent
        max-width="300"
        overlay-opacity=".75"
      >
        <v-card class="text-left">
          <v-card-title class="v-dialogHeader text-left px-3 py-1">
            {{ appDialogType }}
          </v-card-title>
          <v-card-text class="px-3 py-1">
            {{ appDialogMessage }}
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="appDialogRedirect !== false"
              :to="appDialogRedirect"
              color="accent"
              @click="closeAppDialog()"
            >
              Close
            </v-btn>
            <v-btn
              v-else
              color="accent"
              @click="closeAppDialog()"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        :value="inactivityDialog"
        persistent
        max-width="300"
        overlay-opacity=".75"
      >
        <v-card class="text-left">
          <v-card-title class="v-dialogHeader text-left px-3 py-1">
            Inactivity
          </v-card-title>
          <v-card-text class="px-3 py-1">
            Your session has expired.
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              to="/today"
              color="accent"
              @click="logUserOut"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        :value="inactivityWarningDialog"
        persistent
        max-width="300"
        overlay-opacity=".75"
      >
        <v-card class="text-left">
          <v-card-title class="v-dialogHeader text-left px-3 py-1">
            Inactivity Warning
          </v-card-title>
          <v-card-text
            class="px-3 py-1"
          >
            Due to inactivity, your session will expire in 30 seconds.
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="accent"
              to="/today"
              @click="inactivityWarningDialog = false"
            >
              Extend Session
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <AppBar
        :install-prompt="installPrompt"
      />

      <NavigationDrawer />

      <v-main
        class="pt-13"
      >
        <v-container
          id="panels"
          class="mt-0 pt-0"
          fluid
          tag="section"
        >
          <v-idle
            :loop="false"
            :reminders="[30]"
            :wait="5"
            :duration="timeoutDuration"
            class="hide"
            @idle="onidle"
            @remind="onremind"
          />
          <fv-alert />
          <router-view />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { B2CManager } from '@/Lib/B2CManager.js'
import { DataSyncManager } from '@/Lib/DataSyncManager'
import { mapState } from 'vuex'
import { NavigationHelperMixin } from '@/mixins/NavigationHelperMixin.js'
import { referenceDataMixin } from './mixins/referenceDataMixin'
import AuthorizationManager from './Lib/AuthorizationManager'
import Bowser from 'bowser'
import FieldVueModal from './components/infrastructure/FieldVueModal.vue'
import fvEnvVars from '@/fvEnvVars'
import messages from '@/messages'
import osmoseNetworkStatusMixin from './mixins/osmoseNetworkStatusMixin'
import PreferencesManager from '@/components/dynamic/ApplicationPreferences/LocalLib/ApplicationPreferencesManager'
import update from '@/mixins/update'

import { DateTime, Duration } from 'luxon'

export default {
  name: 'App',
  components: { FieldVueModal },
  mixins: [B2CManager, NavigationHelperMixin, osmoseNetworkStatusMixin, update, referenceDataMixin],
  data () {
    return {
      dialogBrowserTitle: 'Message',
      displayAppDialog: false,
      dialogBrowserDescription: `This application requires the use of Google Chrome. Please close this browser
                                    and open this application using Chrome.`,
      disconnected: false,
      displayBrowserDialog: false,
      errorAlertDisplay: false,
      expandOnHover: false,
      headerColor: '',
      inactivityDialog: false,
      inactivityWarningDialog: false,
      installPrompt: null,
      isChrome: false,
      notLoggedIn: true,
      loadedOffline: false,
      loadingDialogMessage: 'Loading FieldVue...',
      logItemsSentToServer: false,
      notificationIds: {
        TEAM_UNAVAILABLE: '01905498-81eb-7177-9a16-a4139715a211',
        TEAM_MEMBER_REMOVED: '01905498-81eb-71db-a6af-ec686477f6eb',
      },
      onStartRoute: this.$router.currentRoute,
      timeoutDuration: 1800,
      updateText: 'Please wait while FieldVue is updating to the latest version.',
    }
  },
  computed: {
    ...mapState({
      preferencesInitialized: (state) => state.applicationPreferencesStore.preferencesInitialized,
      employeeId: (state) => state.authorizationStore.EmployeeId,
      displayLoadingFieldVue: (state) => state.applicationCoreStore.displayLoadingFieldVue,
      displayUnableToLogIn: (state) => state.applicationCoreStore.displayUnableToLogIn,
      fvLoadingMessage: (state) => state.applicationCoreStore.fvLoadingMessage,
      isOnline: (state) => state.applicationCoreStore.isOnline,
    }),
    appDialogType () {
      return this.$store.getters.appDialogType
    },
    appDialogRedirect () {
      return this.$store.getters.appDialogRedirect
    },
    appDialogMessage () {
      return this.$store.getters.appDialogMessage
    },
    appDialogDisplay () {
      return this.$store.getters.appDialogDisplay
    },
    appRegistry () {
      return this.$storage.get('appRegistry')
    },
    userApplications () {
      return this.$store.getters.userApplications
    },
    errorMessage () {
      return this.$store.getters.errorMessage
    },
    firstTimeUser () {
      return this.$store.getters.firstTimeUser
    },
  },
  watch: {
    async preferencesInitialized (newValue, oldValue) {
      await this.isUserLoggedIn()
      if (newValue === true) {
        const tempTeam = PreferencesManager.getPreference({
          projId: this.$store.getters.currentProject.ProjectId,
          appId: this.$store.getters.getUserApplicationId('My Team'),
          key: 'MyTeam',
        })

        this.logItemsSentToServer = this.$azureLogger.sendToServer()

        if (this.firstTimeUser) {
          this.$router.push({ name: 'Initial Login' }).catch(() => {})
        } else if (typeof tempTeam === 'undefined') {
          this.$notificationPlugin.add(
            DateTime.now().toISO(),
            DateTime.now().toISO(),
            this.notificationIds.TEAM_UNAVAILABLE,
            messages.TEAM_UNAVAILABLE,
            'high',
            false
          )

          this.$azureLogger.writeLogItem('error', {
            Application: 'FieldVue',
            FeatureName: 'App.vue',
            ErrorCode: 'TU001',
            ErrorDescription: 'User has no team available in Preferences',
            AdditionalDetails: `Team is: ${tempTeam}`,
          })
        }

        if (!this.$store.getters.impersonatingUser) {
          if (this.isOnline) {
            this.checkAndLogOfflineDuration()
            this.syncPreferences()
            this.kickoffWebWorkerLogic()
          } else {
            const dataSyncManager = new DataSyncManager()
            dataSyncManager.updateSyncBadge()
            this.$store.dispatch('updateTasksSynced', true)
          }
        }
      }
    },
    isOnline (newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        const currentApplication = this.$store.getters.userApplications?.find(item => item.to === this.$router.currentRoute.path.substring(1))
        let redirectTo = false
        let offlineMsg = 'You are currently offline. Some functionality will be disabled until you reconnect.'
        if (currentApplication.OnlineOnly === true) {
          redirectTo = '/today'
          offlineMsg = 'You are currently offline. The page you are viewing is not available offline, when you click on close you will be redirected to the Today page. Some functionality will be disabled until you reconnect.'
        }

        this.disconnected = true
        this.$store.dispatch('displayAppDialog', {
          display: true,
          message: offlineMsg,
          type: 'Warning',
          redirectTo: redirectTo,
        })
        const timestamp = DateTime.now().ts
        this.$storage.set('offlineTimestamp', timestamp)
      } else if (((this.disconnected || this.loadedOffline) && oldValue === false && newValue === true) && !this.inactivityDialog) {
        this.$store.dispatch('displayAppDialog', {
          display: true,
          message: 'You are back online. FieldVue is fully functional.',
          type: 'Info',
          redirectTo: false,
        })
        this.loadedOffline = false
        this.initMsalObj()
        this.syncPreferences()
        const dataSyncManager = new DataSyncManager()
        // update the task data from ServiceNow
        const employeeId = this.$store.getters.employeeId === null ? this.$store.getters.userAuthObject.EmployeeId : this.$store.getters.employeeId
        dataSyncManager.syncTaskData(employeeId)
        this.checkAndLogOfflineDuration()
      }
    },
    updateExists (newValue) {
      if (newValue) {
        this.updateText = 'FieldVue has finished updating to the latest version. Press Okay to refresh'
        const self = this
        if (process.env.NODE_ENV === 'production') {
          navigator.serviceWorker.ready.then((registration) => {
            if (registration) {
              self.updateAvailable(registration)
              this.swDownloading = true // it's already downloaded, just using this to get the dialog to display
            }
          })
        }
      }
    },
    appDialogDisplay (newValue, oldValue) {
      if (newValue === true) {
        this.displayAppDialog = true
      } else {
        // Should be unreachable
        this.displayAppDialog = false
      }
    },
    userAuthObject (newValue, oldValue) {
      // wait for userApplications and app registery before routing to a dynamic app
      if (newValue !== oldValue) {
        this.routerControlAfterAuthObject(this.onStartRoute)
      }
    },
    userApplications (newValue, oldValue) {
      // wait for userApplications and app registery before routing to a dynamic app
      if (newValue !== oldValue) {
        this.routerControlAfterAuthObject(this.onStartRoute)
      }
    },
  },
  beforeMount: function () {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const browserName = browser.getBrowserName()
    const platform = browser.getPlatformType()

    if (platform === 'mobile' || browserName === 'Chrome') {
      this.isChrome = true
    } else {
      this.displayBrowserDialog = true
    }

    if (this.swDownloading === true) {
      this.loadingDialogMessage = 'Downloading a new version of FieldVue...'
    } else {
      this.loadingDialogMessage = 'Loading FieldVue...'
    }

    if (platform === 'mobile') {
      this.timeoutDuration = 7200
    }
  },
  created () {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      // Stash the event so it can be triggered later
      this.installPrompt = e
    })
    window.addEventListener('appinstalled', () => {
      this.installPrompt = null
    })
    this.$store.dispatch(
      'updateClientId',
      fvEnvVars[location.host].VUE_APP_ClientId,
    )
    this.$store.dispatch(
      'updateKnownAuthorities',
      fvEnvVars[location.host].VUE_APP_KNOWN_AUTHORITIES,
    )
    this.$store.dispatch(
      'updateBaseURL',
      fvEnvVars[location.host].VUE_APP_BASE_URL,
    )
    this.$store.dispatch(
      'updateAuthority',
      fvEnvVars[location.host].VUE_APP_Authority,
    )
    this.$store.dispatch(
      'updatePasswordPolicyResetURL',
      fvEnvVars[location.host].VUE_APP_Reset_Password_Policy_URL,
    )
    this.$store.dispatch(
      'updateCustomerProjectURL',
      fvEnvVars[location.host].VUE_APP_CustomerProjectURL,
    )
    this.$store.dispatch(
      'updateAzureProxyBaseURL',
      fvEnvVars[location.host].VUE_APP_AZURE_PROXY_BASE_URL,
    )
    this.$store.dispatch(
      'updateServiceNowBaseURL',
      fvEnvVars[location.host].VUE_APP_SERVICE_NOW_BASE_URL,
    )
    this.$store.dispatch('updateImpersonating', false)
    this.$store.dispatch('updateImpersonatingEmailAddress', '')
    this.$store.dispatch(
      'updateWebOpsBaseURL',
      fvEnvVars[location.host].VUE_APP_WEBOPS_BASE_URL,
    )
    this.$store.dispatch(
      'updatePONIQueryBaseURL',
      fvEnvVars[location.host].VUP_APP_AZURE_PONI_QUERY_BASE_URL,
    )
    this.$store.dispatch(
      'updateEsriApiKey',
      fvEnvVars['all'].VUE_APP_ESRI_API_KEY,
    )
    this.$store.dispatch(
      'updateEsriGeocodeURL',
      fvEnvVars['all'].VUE_APP_ESRI_GEOCODE_URL,
    )
  },
  async mounted () {
    setTimeout(() => {
      if (this.notLoggedIn) {
        const loadingMessage = 'FieldVue is taking too long to load. Check your connection and refresh the page.'
        this.$store.dispatch('setFvLoadingMessage', loadingMessage)
        this.$azureLogger.writeLogItem('error', {
          Application: 'FieldVue',
          FeatureName: 'App.vue',
          ErrorCode: 'SlowLoading',
          ErrorDescription: 'FieldVue failed to load after 90 seconds.',
          AdditionalDetails: '',
        })
      }
    }, 90000)
    if (this.isOnline) {
      // remove crewPoniCounts and dotFailures from LS so they get refreshed at the next opportunity
      this.$storage.remove('crewPoniCounts')
      this.$storage.remove('previousCrewPoniCounts')
      this.$storage.remove('fulcrumCrewPoniCounts')
      this.$storage.remove('previousFulcrumCrewPoniCounts')
      this.$storage.remove('dotFailures')
      try {
        await this.initMsalObj()
      } catch (err) {
        this.$azureLogger.writeLogItem('error', {
          Application: 'FieldVue',
          FeatureName: 'App.vue',
          ErrorCode: err.name,
          ErrorDescription: 'Failed to initialize msal object',
          AdditionalDetails: err.message,
        })
      }
    } else {
      this.loadedOffline = true
      // need to do what this.initMsalObj() does...only offline  :-/
      this.selectAccount()

      AuthorizationManager.createAuthInfo(
        AuthorizationManager.getConfigurationFromLocalStorage('userAuthObject'),
        AuthorizationManager.getConfigurationFromLocalStorage('appRegistry'),
        'dispatch',
        'updateAuthInfo',
      )

      this.$store.dispatch('updateUserAuthObject', this.$storage.get('userAuthObject'))
      this.$store.dispatch('setPreferencesInitialized', true)
    }

    this.$router.beforeEach((to, from, next) => {
      // prevent duplicate navigation
      if (to.name === from.name) {
        next(false)
      } else {
        next()
      }
    })
  },
  methods: {
    reload () {
      this.$router.go()
    },
    onidle () {
      this.inactivityWarningDialog = false
      if (this.isOnline) {
        this.inactivityDialog = true
      }
    },
    onremind (time) {
      // alert seconds remaining to 00:00
      if (this.isOnline) {
        this.inactivityWarningDialog = true
      }
    },
    logUserOut () {
      if (this.isOnline) {
        this.logOut()
      }
    },
    closeAppDialog () {
      this.displayAppDialog = false
      var self = this
      setTimeout(function () {
        self.$store.dispatch('closeAppDialog', { display: false })
      }, 100)
    },
    async routerControlAfterAuthObject (currentRoute) {
      if (
        currentRoute.path === '/' ||
        currentRoute.path === '/login'
      ) {
        // console.log('dispatching my loggedIn event')
        // const event = new CustomEvent('loggedIn', { detail: true })
        // document.dispatchEvent(event)

        this.routeToDynamicApp('today')
      } else if (currentRoute.path === '/today') {
        this.routeToDynamicApp('today')
      } else {
        this.routeToDynamicApp(false)
      }
    },
    routeToDynamicApp (toPath = false) {
      let appName = ''
      if (String(window.performance.getEntriesByType('navigation')[0].type) === 'reload') {
        const path = toPath === false ? this.onStartRoute.path : toPath
        appName = path.replace('/', '') // removes forward slash
      } else {
        appName = 'today'
      }
      let userIsAuthorizedForApp = false
      let appToNavigateTo = false
      this.appRegistry.forEach(function (currentApp) {
        // this regex removes all white space
        if (currentApp.Name.replace(/\s+/g, '').toLowerCase() === appName) {
          appToNavigateTo = currentApp
        }
      })

      this.userApplications.forEach(function (currentApp) {
        if (currentApp.Name.replace(/\s+/g, '').toLowerCase() === appName) {
          userIsAuthorizedForApp = true
        }
      })

      if (appToNavigateTo && userIsAuthorizedForApp) {
        this.navigate(
          appToNavigateTo.Name.replace(/\s+/g, '').toLowerCase(),
          appToNavigateTo.PageTitle,
          appToNavigateTo.EntryPoint,
        )
      }
    },
    navigate (routePath, routeName, entryPoint) {
      if (!this.hasRoute(routeName)) {
        // add child routes here TODO
        this.$router.addRoute({
          path: `/${routePath}`,
          name: routeName,
          component: () => import(`./components/dynamic/${entryPoint}`),
        })
      }

      this.pushRouteTo({ path: routePath })
    },
    hasRoute (routeName) {
      let retVal = false
      const routes = this.$router.getRoutes()
      routes.forEach((route) => {
        if (route.name === routeName) {
          retVal = true
        }
      })
      return retVal
    },
    isUserLoggedIn () {
      if (!this.$store.getters.authToken && this.isOnline) {
        this.loggingOff = true
        this.notLoggedIn = true
      } else {
        this.loggingOff = false
        this.notLoggedIn = false
      }
    },
    async syncPreferences () {
      await PreferencesManager.saveApplicationPreferencesToTheCloud()
    },
    async kickoffWebWorkerLogic () {
      const webWorkerParams = {
        firstTimeUser: this.firstTimeUser,
        baseURL: this.$store.getters.azureProxyBaseURL,
        employeeId: this.$store.getters.employeeId,
        userId: this.$store.getters.userId,
        token: this.$store.getters.authToken,
      }

      const dataSyncManager = new DataSyncManager()

      let tasksSynced = false
      // If task definitions are available
      if (await this.getTaskDefinitions()) {
        // update the task data from ServiceNow
        await dataSyncManager.syncTaskData(this.$store.getters.employeeId)
        tasksSynced = true
      }

      // start of reference data gets/updates
      await dataSyncManager.updateAllReferenceData(webWorkerParams)

      if (!this.firstTimeUser && !this.$store.getters.impersonatingUser) {
        // eslint-disable-next-line
        let inactiveTeamMembers = await dataSyncManager.updateActiveEmployees(webWorkerParams)

        // is it necessary to notifiy the user of any team member(s) which may no longer be Osmose employees
        if (inactiveTeamMembers && inactiveTeamMembers.length > 0) {
          let message = ''
          inactiveTeamMembers.forEach(member => {
            message += member.Name + ', '
          })
          
          this.$notificationPlugin.add(
            DateTime.now().toISO(),
            DateTime.now().toISO(),
            this.notificationIds.TEAM_MEMBER_REMOVED,
            messages.TEAM_MEMBER_REMOVED + message.substring(0, message.length - 2),
            'high',
            false
          )
        }
      }

      // If they weren't synced before reference data
      if (!tasksSynced) {
        // update the task data from ServiceNow
        await dataSyncManager.syncTaskData(this.$store.getters.employeeId)
      }
    },
    checkAndLogOfflineDuration () {
      const currentApplication = this.$store.getters.userApplications?.find(item => item.to === this.$router.currentRoute.path.substring(1))
      const offlineTimestamp = this.$storage.get('offlineTimestamp')
      if (offlineTimestamp) {
        const duration = DateTime.now().ts - offlineTimestamp
        const timeOffline = Duration.fromMillis(duration).toFormat('hh:mm:ss')
        this.$storage.remove('offlineTimestamp')
        this.$azureLogger.writeLogItem('activity', {
          Application: currentApplication.Name,
          FeatureName: 'Offline: ' + DateTime.fromMillis(offlineTimestamp).toFormat('yyyy-MM-dd HH:mm:ss'),
          ActivityType: this.$store.getters.loggingActivityType.disconnected,
          AdditionalDetails: 'Duration: ' + timeOffline,
        })
      }
    },
  },
}
</script>

<style>
body {
  background-image: url('./assets/Background.png');
  background-size: cover;
  height: 100%;
  overscroll-behavior: none;
}
html{
  overscroll-behavior: none;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.hide {
  display: none;
}
.FVLogo {
  width: 185px;
  opacity: 1;
}
.header {
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 20% 20%;
  height: 70px;
  background: transparent;
  opacity: 1;
  min-width: 1385px;
}
.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar-expand-lg {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.top-bar {
  grid-column-start: 1;
  grid-column-end: end;
  height: 70px;
  padding: 10px 0 0 10px !important;
}
</style>
